import { useQuery } from 'react-query'
import { action, observable } from 'mobx'

import API from '../common/api'
import { ReportsResponse, ReportTypesResponse } from '../common/api/reporting/reports'
import PaginationStore from '../common/PaginationStore'

import { StoreNames } from 'common/constants'

class ReportingStore extends PaginationStore {
  storeName: string = StoreNames.REPORTING_STORE

  @observable
  reportId: string = ''

  @observable
  type: string = ''

  @observable
  status: string = ''

  @action
  handleTypeSearch = (filter: string) => {
    this.type = filter?.trim()
    this.changePage()
  }

  @action
  handleStatusSearch = (filter: string) => {
    this.status = filter?.trim()
    this.changePage()
  }
}

export const useReportsQuery = (filterStore: ReportingStore) => {
  const { pageSize, page, type, status } = filterStore

  return useQuery<ReportsResponse>(
    ['reports', pageSize, page, type, status],
    () => {
      return API.reports.list({
        size: pageSize,
        page: page,
        type,
        status,
      })
    },
    {
      retry: 1,
      keepPreviousData: true,
    },
  )
}

export const useReportTypesQuery = (callTypes: boolean = false) => {
  return useQuery<ReportTypesResponse>(
    ['reportTypes', callTypes],
    () => {
      return API.reports.getReportTypes()
    },
    {
      enabled: callTypes,
      retry: 1,
      keepPreviousData: true,
    },
  )
}

export default ReportingStore
