/* eslint-disable jsx-control-statements/jsx-for-require-each */
import React, { useEffect, useState, useRef } from 'react'
import { useMutation } from 'react-query'
import { observer } from 'mobx-react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import * as R from 'ramda'
import { Duration } from 'luxon'

import useStores from 'common/hook/useStore'

import { useAdQuery } from '../../RadioAdsStore'
import { useTerritoriesQuery } from 'territories/TerritoriesStore'

import { Box, Chip, FormControl, Autocomplete, TextField as MuiTextField, Checkbox } from '@mui/material'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Text from 'locale/strings'
import TextField from 'ui/TextField/TextField'
import Button from 'ui/Button/Button'
import Spinner from 'ui/Spinner/Spinner'

import { handleTypeNumber } from 'common/utils'

import API from 'common/api'
import { ApiStatuses, FileType } from 'common/constants'
import { RadioAdRequest } from 'common/api/radioAds/radioAds'
import { Territory } from 'common/api/territory/territory'

import styles from './RadioAd.module.scss'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const schema = yup.object().shape({
  adName: yup.string().required('Name is required').max(30, 'Name must be at most 30 characters'),
  ageRestriction: yup.number().typeError('Must be a number').min(1, 'Field value must be greater than or equal to 1'),
})

interface AdProps {
  id?: string
  playlistId: string
  adGroup: any
  refetchAdForm: () => void
}

interface Ad {
  adName: string
  availability: string[]
  adGroupId: string
  ageRestriction: number | string
  fileId: string
  duration: string
}

const RadioAd = ({ id, playlistId, adGroup, refetchAdForm }: AdProps) => {
  const { notificationsStore } = useStores()

  const { data: territories, status: territoriesStatus } = useTerritoriesQuery()
  const { data: ad } = useAdQuery(id)

  const [allTerritories, setAllTerritories] = useState<Territory[]>([])
  const [selectedTerritories, setSelectedTerritories] = useState<Territory[]>([])

  const audioRef = useRef<any>(null)
  const [url, setUrl] = useState('')
  const [adFile, setAdFile] = useState<Nullable<File>>(null)
  const [adError, setAdError] = useState<boolean>(false)
  const [adFormatError, setAdFormatError] = useState<boolean>(false)
  const [duration, setDuration] = useState<number>()
  const [availabilitiesError, setAvailabilitiesError] = useState<boolean>(false)

  const [initialValues, setInitialValues] = useState({
    adName: '',
    availability: [],
    adGroupId: '',
    ageRestriction: '',
    fileId: '',
    duration: '',
  })

  const initialRadioAd = id !== '' ? ad : null

  const mutationAd = useMutation<any, Error, RadioAdRequest>(
    (body: RadioAdRequest) => {
      if (!R.isNil(initialRadioAd)) {
        return API.radioAds.editAd(body)
      }

      return API.radioAds.createAd(body)
    },
    {
      onSuccess: () => {
        clearForm()

        !R.isNil(initialRadioAd)
          ? notificationsStore.successNotification('Ad successfully edited')
          : notificationsStore.successNotification('Ad successfully added')
      },
      onError: (error: any) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  useEffect(() => {
    if (!R.isNil(initialRadioAd)) {
      setInitialValues({
        adName: initialRadioAd.name,
        availability: initialRadioAd.availability,
        adGroupId: adGroup.id,
        ageRestriction: initialRadioAd.ageRestriction,
        fileId: '',
        duration: Duration.fromISO(initialRadioAd.duration).toFormat('HH:mm:ss'),
      })

      setSelectedTerritories(initialRadioAd.availability)
      setFieldValue('adGroupId', initialRadioAd.adGroupId)
      setUrl(initialRadioAd.adTrackUrl)
    }
  }, [ad])

  useEffect(() => {
    if (territories?.territories) {
      setAllTerritories(territories?.territories)
    }
  }, [territories])

  const handlePresubmit = () => {
    if (selectedTerritories.length === 0) {
      setAvailabilitiesError(true)
    }

    if (R.isNil(adFile) && !url) {
      setAdError(true)
    }

    formik.handleSubmit()
  }

  const handleSubmit = (values: Ad) => {
    let uploadId: string
    let uploadUrl: string

    if (selectedTerritories.length === 0 || (R.isNil(adFile) && !url)) {
      return
    }

    setAvailabilitiesError(false)
    setAdError(false)

    const getDataForAdUpload = API.general.getUploadData({
      fileContentType: FileType.AD,
    })

    if (R.isNil(initialRadioAd)) {
      getDataForAdUpload
        .then((response) => {
          uploadId = response.id
          uploadUrl = response.uploadUrl

          return fetch(uploadUrl, {
            method: 'PUT',
            body: adFile,
          })
        })
        .then(() => {
          mutationAd.mutate({
            adGroupId: adGroup.id,
            playlistId: playlistId,
            name: values.adName.trim(),
            availability: selectedTerritories.map((territory) => territory.territoryCode),
            ageRestriction: values.ageRestriction,
            fileId: uploadId,
            duration,
          })
        })
    } else if (R.isNil(adFile)) {
      mutationAd.mutate({
        id: initialRadioAd.id,
        adGroupId: adGroup.id,
        name: values.adName.trim(),
        availability: selectedTerritories.map((territory) => territory.territoryCode),
        ageRestriction: values.ageRestriction,
        duration,
      })
    } else {
      getDataForAdUpload
        .then((response) => {
          uploadId = response.id
          uploadUrl = response.uploadUrl

          return fetch(uploadUrl, {
            method: 'PUT',
            body: adFile,
          })
        })
        .then(() => {
          mutationAd.mutate({
            id: initialRadioAd.id,
            adGroupId: adGroup.id,
            name: values.adName.trim(),
            availability: selectedTerritories.map((territory) => territory.territoryCode),
            ageRestriction: values.ageRestriction,
            fileId: uploadId,
            duration,
          })
        })
    }
  }

  const formik = useFormik<Ad>({
    initialValues: initialValues,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  })

  const { values, setFieldValue } = formik

  const handleChangeTerritories = (e: React.SyntheticEvent, value: Territory[]) => {
    setSelectedTerritories(value)
    setAvailabilitiesError(false)
  }

  const handleDeleteTerritory = (value: any) => {
    setSelectedTerritories((territoryArray) =>
      territoryArray.filter((territoryOption) => territoryOption.territoryCode !== value),
    )
  }

  useEffect(() => {
    if (!R.isNil(adFile)) {
      const url = URL.createObjectURL(adFile)

      setUrl(url)

      if (audioRef.current) {
        audioRef.current.load()
      }
    }
  }, [adFile])

  const handleAdUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]

      if (file.type === 'audio/mpeg' || file.type === 'audio/vnd.dlna.adts') {
        setAdFile(file)
        setAdError(false)
      } else {
        setAdFormatError(true)

        setTimeout(() => setAdFormatError(false), 3000)
      }
    }
  }

  const handleLoadedMetadata = (event: any) => {
    setDuration(event.target.duration)
  }

  const clearForm = () => {
    setFieldValue('adName', '')
    setFieldValue('ageRestriction', '')
    setSelectedTerritories([])
    setAdFile(null)
    setUrl('')
    setDuration(undefined)
    refetchAdForm()
  }

  return (
    <React.Fragment>
      {id !== '' && R.isNil(initialRadioAd) ? (
        <Spinner />
      ) : (
        <div className={styles.container}>
          {territoriesStatus === ApiStatuses.LOADING ? (
            <Spinner />
          ) : (
            <div className={styles.form}>
              <div className={styles.intro}>
                {R.isNil(initialRadioAd) ? (
                  <p>{Text.radioAds.create}</p>
                ) : (
                  <React.Fragment>
                    <p>{Text.radioAds.editAd}</p>
                    <Button data-test="submit-button" onClick={clearForm}>
                      Create new ad
                    </Button>
                  </React.Fragment>
                )}
              </div>
              <div className={styles.leftWrapper}>
                <TextField
                  fullWidth
                  data-test="ad-name-field"
                  label={Text.fields.name + ' *'}
                  name="adName"
                  value={values.adName}
                  error={formik.errors.adName}
                  inputProps={{ maxLength: 30 }}
                  onChange={formik.handleChange}
                />
                <FormControl fullWidth className={styles.customSelect}>
                  <div className={styles.autocompleteWrapper}>
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      data-test="available-countries-field"
                      id="availableCountries"
                      className={styles.autocompleteField}
                      isOptionEqualToValue={(option, value) => option.territoryCode === value.territoryCode}
                      value={selectedTerritories}
                      options={allTerritories}
                      getOptionLabel={(option: Territory) => option.territoryName}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.territoryName}
                        </li>
                      )}
                      renderTags={() => null}
                      renderInput={(params) => (
                        <MuiTextField {...params} label="Choose territories" placeholder="Search" />
                      )}
                      onChange={handleChangeTerritories}
                    />
                    {selectedTerritories.length > 0 && (
                      <Box className={styles.autocompleteFieldValues}>
                        {selectedTerritories.map((option) => (
                          <Chip
                            className={styles.autocompleteFieldValue}
                            key={option.territoryCode}
                            label={option.territoryName}
                            onDelete={() => handleDeleteTerritory(option.territoryCode)}
                          />
                        ))}
                      </Box>
                    )}
                  </div>
                  <If condition={availabilitiesError}>
                    <span className={styles.customError}>{Text.common.fieldIsRequired}</span>
                  </If>
                </FormControl>
                <TextField
                  fullWidth
                  data-test="age-restriction-field"
                  type="number"
                  label={Text.common.ageRestriction}
                  name="ageRestriction"
                  value={values.ageRestriction}
                  error={formik.errors.ageRestriction}
                  InputProps={{
                    inputProps: {
                      min: 1,
                    },
                  }}
                  onKeyPress={(e) => handleTypeNumber(e)}
                  onChange={formik.handleChange}
                />
              </div>
              <div className={styles.rightWrapper}>
                <div>
                  <label htmlFor="file-ad">
                    <input
                      hidden
                      id="file-ad"
                      type="file"
                      accept="audio/aac, audio/mp3"
                      value=""
                      onChange={handleAdUpload}
                    />
                    <Button isUpload data-test="upload-ad-button" className={styles.uploadButton}>
                      {Text.page.ad.uploadAd}
                    </Button>
                    <If condition={adError && R.isNil(initialRadioAd)}>
                      <span className={styles.customError}>{Text.page.ad.adError}</span>
                    </If>
                    <If condition={adFormatError}>
                      <span className={styles.customError}>{Text.page.ad.supportedFiles}</span>
                    </If>
                  </label>
                  <If condition={!R.isNil(adFile)}>
                    <span>{adFile?.name}</span>
                  </If>
                </div>
                {url && (
                  <audio
                    controls
                    controlsList="nodownload"
                    preload="metadata"
                    ref={audioRef}
                    className={styles.audioPreview}
                    onLoadedMetadata={handleLoadedMetadata}
                  >
                    <source src={url} />
                  </audio>
                )}
              </div>
              <div className={styles.submitContainer}>
                <Button data-test="submit-button" onClick={handlePresubmit}>
                  {R.isNil(initialRadioAd) ? Text.radioAds.addAd : Text.radioAds.editAd}
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  )
}

export default observer(RadioAd)
