import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'

import ReportingStore from '../ReportingStore'
import ReportsTable from './ReportsTable/ReportsTable'

const Reports = () => {
  const reportingStore = new ReportingStore()

  return <ReportsTable reportingStore={reportingStore} />
}

export default thread(Reports, [observer])
