/* eslint-disable jsx-control-statements/jsx-for-require-each */
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Duration } from 'luxon'

import { useStyngDeactivate } from 'common/hook/useStyngDeactivate'
import { useStyngActivate } from 'common/hook/useStyngActivate'
import { useStyngsQuery } from '../../StyngsStore'

import Spinner from 'ui/Spinner/Spinner'
import SectionFilters from 'ui/SectionFilters/SectionFilters'
import Table from 'ui/Table/Table'
import Pagination from 'ui/Pagination/Pagination'
import JsonDataModalWithPlatform from 'ui/JsonDataModal/JsonDataModalWithPlatform/JsonDataModalWithPlatform'

import { Styng } from 'common/api/styng/styng'
import { formatGenres } from 'common/hook/formatGenre'
import useStores from 'common/hook/useStore'
import { truncateText } from 'common/utils'
import { FileType, FilterFields, ApiStatuses, INITIAL_PAGE } from 'common/constants'

import EditStyngStore from '../../../styngs/CreateStyng/CreateStyngStore'
import NftStore from '../../NftModal/NftStore'

import StyngModal from '../../StyngModal/StyngModal'
import NftModal from '../../NftModal/NftModal'

import styles from './StyngsLsrTable.module.scss'

const StyngsLsrTable = () => {
  const { styngsStore, editStyngStore } = useStores()
  const { data: dataLsr, refetch: refetchLsr, status: statusLsr } = useStyngsQuery(styngsStore)

  const styngsLsr = dataLsr?.items ?? []
  const pagesCountLsr = dataLsr?.pagesCount ?? 1

  const createStyngStore = new EditStyngStore()
  const createNftStore = new NftStore()

  const [selectedStyngId, setSelectedStyngId] = useState<string>('')
  const [selectedStyng, setSelectedStyng] = useState<Styng | null>(null)
  // Add modal / Edit modal
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showNftModal, setShowNftModal] = useState<boolean>(false)
  const [jsonDataModal, setJsonDataModal] = useState<boolean>(false)

  useEffect(() => {
    styngsStore.changePage(INITIAL_PAGE)
  }, [])

  const handleEditClick = (id: string) => {
    setSelectedStyngId(id)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    editStyngStore.setTrack(null)
    setShowModal(false)
    setSelectedStyngId('')
  }

  //NFT modal
  const handleCreateNftClick = (id: string) => {
    setSelectedStyngId(id)
    setShowNftModal(true)
  }

  const handleCloseNftModal = () => {
    setSelectedStyngId('')
    setShowNftModal(false)
  }

  // Pagination logic
  const handleChangePage = (value: number) => {
    styngsStore.changePage(value)
  }

  // Table data
  const columns = [
    'Image',
    'Id',
    'ISRC',
    'Song name',
    'Artist',
    'Styng name',
    'Genres',
    'Duration',
    'Published',
    'External Metadata',
    'Actions',
  ]

  const dataTable = styngsLsr.map((styng: Styng) => [
    styng.imageUrl,
    styng.id,
    styng.trackIsrc,
    styng.trackTitle,
    styng.artist,
    truncateText(styng.name),
    formatGenres(styng.genres),
    Duration.fromISO(styng.duration).toFormat('mm:ss'),
    styng.isActive.toString(),
    Object.keys(styng.metadata).length === 0 ? 'NULL' : '',
  ])

  const onActivateLsrMutation = useStyngActivate((_: any) => {
    refetchLsr()
  })

  const onDeactivateLsrMutation = useStyngDeactivate((_: any) => {
    refetchLsr()
  })

  const handleActivate = (id: string, active: string) => {
    if (active === 'false') {
      onActivateLsrMutation.mutate({ styngId: id })
    } else {
      onDeactivateLsrMutation.mutate({ styngId: id })
    }
  }

  const handleOpenAdJsonModal = (id: string) => {
    const styng = styngsLsr.filter((styng: Styng) => styng.id === id)

    setSelectedStyng(styng[0])
    setJsonDataModal(true)
  }

  const handleCloseAdJsonModal = () => {
    setSelectedStyng(null)
    setJsonDataModal(false)
  }

  return (
    <div className={styles.container}>
      <SectionFilters
        sectionStore={styngsStore}
        filters={[
          FilterFields.ISRC,
          FilterFields.NAME,
          FilterFields.ARTIST,
          FilterFields.SONG_NAME,
          FilterFields.GENRE,
          FilterFields.PUBLISHED,
        ]}
      />
      {onActivateLsrMutation.isLoading || onDeactivateLsrMutation.isLoading || statusLsr === ApiStatuses.LOADING ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <Table
            jsonData
            hideDelete
            columns={columns}
            data={dataTable}
            hiddenColumns={['Id', 'External Metadata']}
            publishedToggle={true}
            nftIcon={true}
            handleActivate={handleActivate}
            onEditClick={handleEditClick}
            onCreateNftClick={handleCreateNftClick}
            onJsonDataClick={handleOpenAdJsonModal}
          />

          <If condition={pagesCountLsr > 1}>
            <div className={styles.paginationContainer}>
              <div className={styles.pagination}>
                <Pagination page={styngsStore.page} count={pagesCountLsr} onChange={handleChangePage} />
              </div>
            </div>
          </If>
        </React.Fragment>
      )}

      {showModal && (
        <StyngModal
          editStyngStore={selectedStyngId === '' ? createStyngStore : editStyngStore}
          styngId={selectedStyngId}
          open={showModal}
          handleClose={handleCloseModal}
        />
      )}

      {showNftModal && (
        <NftModal
          nftStore={createNftStore}
          refetch={refetchLsr}
          styngId={selectedStyngId}
          open={showNftModal}
          handleClose={handleCloseNftModal}
        />
      )}

      {jsonDataModal && (
        <JsonDataModalWithPlatform
          dataTypeId={selectedStyng?.id}
          data={selectedStyng?.metadata}
          dataType={FileType.STYNG}
          open={jsonDataModal}
          refetch={refetchLsr}
          handleClose={handleCloseAdJsonModal}
        />
      )}
    </div>
  )
}

export default observer(StyngsLsrTable)
