/* eslint-disable react/jsx-sort-props */
import { useState, useEffect } from 'react'

import Select from 'ui/Select/Select'
import TextField from 'ui/TextField/TextField'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import { Box, Chip, FormControl, Autocomplete, TextField as MuiTextField, Checkbox } from '@mui/material'

import Text from '../../locale/strings'
import { FilterFields, StoreNames, toReportsTypeOptions } from 'common/constants'
import { Territory } from 'common/api/territory/territory'

import { useGenresStyngsQuery } from 'genres/GenresStore'
import { useTerritoriesQuery } from 'territories/TerritoriesStore'
import { useReportTypesQuery } from 'reporting/ReportingStore'

import { OptionType } from 'common/api/common/common'
import { Genre } from 'common/api/genre/genre'

import styles from './SectionFilters.module.scss'

interface SectionFiltersProps {
  sectionStore: any
  filters: string[]
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const deafultFilterOptions: OptionType[] = [
  { label: 'All', value: 'null' },
  { label: 'Licensed', value: 'LICENSED' },
  { label: 'Royalty-free', value: 'ROYALTY_FREE' },
]

const deafultSourceOptions: OptionType[] = [
  { label: 'All', value: 'null' },
  { label: 'Licensed', value: 'LSR' },
  { label: 'Royalty-free', value: 'ROYALTY_FREE' },
]

let sourceFilterOptions: OptionType[] = []

const publishFilterOptions: OptionType[] = [
  { label: 'All', value: 'null' },
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
]

let typeFilterOptions: OptionType[] = []

const statusFilterOptions: OptionType[] = [
  { label: 'All', value: 'null' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Delivered', value: 'DELIVERED' },
  { label: 'Generation failed', value: 'GENERATION_FAILED' },
]

const SectionFilters = ({ sectionStore, filters }: SectionFiltersProps) => {
  const storeName = sectionStore.storeName ?? ''

  const { data: territories, status: territoriesStatus } = useTerritoriesQuery()
  const { data: genresData, status: genresStatus } = useGenresStyngsQuery()

  const { data: reportTypes, status: reportTypesStatus } = useReportTypesQuery(storeName === StoreNames.REPORTING_STORE)

  const [idFilter, setIdFilter] = useState<string>('')
  const [debouncedCompanyIdValue, setDebouncedCompanyIdValue] = useState<string>('')
  const [nameFilter, setNameFilter] = useState<string>('')
  const [debouncedNameValue, setDebouncedNameValue] = useState<string>('')
  const [isrcFilter, setIsrcFilter] = useState<string>('')
  const [debouncedIsrcValue, setDebouncedIsrcValue] = useState<string>('')
  const [artistFilter, setArtistFilter] = useState<string>('')
  const [debouncedArtistValue, setDebouncedArtistValue] = useState<string>('')
  const [songNameFilter, setSongNameFilter] = useState<string>('')
  const [debouncedSongNameValue, setDebouncedSongNameValue] = useState<string>('')
  const [playlistFilter, setPlaylistFilter] = useState<string>('')
  const [debouncedPlaylistValue, setDebouncedPlaylistValue] = useState<string>('')
  const [ageRestrictionFilter, setAgeRestrictionFilter] = useState<string>('')
  const [debouncedAgeRestrictionValue, setDebouncedAgeRestrictionValue] = useState<string>('')
  const [platformFilter, setPlatformFilter] = useState<string>('')
  const [debouncedPlatformValue, setDebouncedPlatformValue] = useState<string>('')
  const [appFilter, setAppFilter] = useState<string>('')
  const [debouncedAppValue, setDebouncedAppValue] = useState<string>('')
  const [prefixFilter, setPrefixFilter] = useState<string>('')
  const [debouncedPrefixValue, setDebouncedPrefixValue] = useState<string>('')
  const [publishFilter, setPublishFilter] = useState<boolean | null>(null)
  const [typeFilter, setTypeFilter] = useState<string>('')
  const [statusFilter, setStatusFilter] = useState<string>('')
  const [sourceFilter, setSourceFilter] = useState<string>('')

  const [allGenres, setAllGenres] = useState<Genre[]>([])
  const [selectedGenres, setSelectedGenres] = useState<Genre[]>([])

  const [allTerritories, setAllTerritories] = useState<Territory[]>([])
  const [selectedTerritories, setSelectedTerritories] = useState<Territory[]>([])

  useEffect(() => {
    if (storeName === StoreNames.STYNGLISTS_STORE) {
      sourceFilterOptions = deafultSourceOptions
    }
  }, [])

  useEffect(() => {
    if (storeName !== StoreNames.REPORTING_STORE) {
      typeFilterOptions = deafultFilterOptions

      return
    }

    const data = reportTypes?.reportTypes ?? []

    typeFilterOptions = data.map(toReportsTypeOptions)

    typeFilterOptions.unshift({ label: 'All', value: 'null' })
  }, [reportTypesStatus])

  // ID FLOW
  const handleIdFilter = (value: string) => {
    setIdFilter(value)
  }

  useEffect(() => {
    const timeoutCompanyId = setTimeout(() => {
      setDebouncedCompanyIdValue(idFilter)
    }, 500)

    return () => clearTimeout(timeoutCompanyId)
  }, [idFilter])

  useEffect(() => {
    if (sectionStore.handleIdSearch) {
      sectionStore.handleIdSearch(debouncedCompanyIdValue)
    }
  }, [debouncedCompanyIdValue])

  // NAME FLOW
  const handleNameFilter = (value: string) => {
    setNameFilter(value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedNameValue(nameFilter)
    }, 500)

    return () => clearTimeout(timeoutId)
  }, [nameFilter])

  useEffect(() => {
    if (sectionStore.handleNameSearch) {
      sectionStore?.handleNameSearch(debouncedNameValue)
    }
  }, [debouncedNameValue])

  // ISRC FLOW
  const handleIsrcFilter = (value: string) => {
    setIsrcFilter(value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedIsrcValue(isrcFilter)
    }, 500)

    return () => clearTimeout(timeoutId)
  }, [isrcFilter])

  useEffect(() => {
    if (sectionStore.handleIsrcSearch) {
      sectionStore?.handleIsrcSearch(debouncedIsrcValue)
    }
  }, [debouncedIsrcValue])

  // AGE RESTRICTION FLOW
  const handleAgeRestrictionFilter = (value: string) => {
    setAgeRestrictionFilter(value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedAgeRestrictionValue(ageRestrictionFilter)
    }, 500)

    return () => clearTimeout(timeoutId)
  }, [ageRestrictionFilter])

  useEffect(() => {
    if (sectionStore.handleAgeRegistrationSearch) {
      sectionStore?.handleAgeRegistrationSearch(debouncedAgeRestrictionValue)
    }
  }, [debouncedAgeRestrictionValue])

  // AVAILABILITY FLOW
  useEffect(() => {
    if (territories?.territories) {
      setAllTerritories(territories?.territories)
    }
  }, [territories])

  const handleChangeTerritories = (e: React.SyntheticEvent, value: Territory[]) => {
    setSelectedTerritories(value)
  }

  const handleDeleteTerritory = (value: any) => {
    setSelectedTerritories((territoryArray) =>
      territoryArray.filter((territoryOption) => territoryOption.territoryCode !== value),
    )
  }

  useEffect(() => {
    if (sectionStore.handleTerritorySearch) {
      sectionStore?.handleTerritorySearch(selectedTerritories.map((territory) => territory.territoryCode))
    }
  }, [selectedTerritories])

  // PLATFORM FLOW
  const handlePlatformFilter = (value: string) => {
    setPlatformFilter(value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedPlatformValue(platformFilter)
    }, 500)

    return () => clearTimeout(timeoutId)
  }, [platformFilter])

  useEffect(() => {
    if (sectionStore.handlePlatformSearch) {
      sectionStore.handlePlatformSearch(debouncedPlatformValue)
    }
  }, [debouncedPlatformValue])

  // APP FLOW
  const handleAppFilter = (value: string) => {
    setAppFilter(value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedAppValue(appFilter)
    }, 500)

    return () => clearTimeout(timeoutId)
  }, [appFilter])

  useEffect(() => {
    if (sectionStore.handleAppSearch) {
      sectionStore.handleAppSearch(debouncedAppValue)
    }
  }, [debouncedAppValue])

  // PREFIX FLOW
  const handlePrefixFilter = (value: string) => {
    setPrefixFilter(value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedPrefixValue(prefixFilter)
    }, 500)

    return () => clearTimeout(timeoutId)
  }, [prefixFilter])

  useEffect(() => {
    if (sectionStore.handlePrefixSearch) {
      sectionStore.handlePrefixSearch(debouncedPrefixValue)
    }
  }, [debouncedPrefixValue])

  // ARTIST FLOW
  const handleArtistFilter = (value: string) => {
    setArtistFilter(value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedArtistValue(artistFilter)
    }, 500)

    return () => clearTimeout(timeoutId)
  }, [artistFilter])

  useEffect(() => {
    if (sectionStore.handleArtistSearch) {
      sectionStore?.handleArtistSearch(debouncedArtistValue)
    }
  }, [debouncedArtistValue])

  // SONG NAME FLOW
  const handleSongNameFilter = (value: string) => {
    setSongNameFilter(value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSongNameValue(songNameFilter)
    }, 500)

    return () => clearTimeout(timeoutId)
  }, [songNameFilter])

  useEffect(() => {
    if (sectionStore.handleSongNameSearch) {
      sectionStore?.handleSongNameSearch(debouncedSongNameValue)
    }
  }, [debouncedSongNameValue])

  // GENRE FLOW
  useEffect(() => {
    if (genresData?.genres) {
      setAllGenres(genresData?.genres)
    }
  }, [genresData])

  const handleChangeGenres = (e: React.SyntheticEvent, value: Genre[]) => {
    setSelectedGenres(value)
  }

  const handleDeleteGenre = (value: any) => {
    setSelectedGenres((genreArray) => genreArray.filter((genreOption) => genreOption.id !== value))
  }

  useEffect(() => {
    if (sectionStore.handleGenreSearch) {
      sectionStore?.handleGenreSearch(selectedGenres.map((genre) => genre.id))
    }
  }, [selectedGenres])

  // PLAYLIST FLOW
  const handlePlaylistFilter = (value: string) => {
    setPlaylistFilter(value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedPlaylistValue(playlistFilter)
    }, 500)

    return () => clearTimeout(timeoutId)
  }, [playlistFilter])

  useEffect(() => {
    if (sectionStore.handlePlaylistSearch) {
      sectionStore?.handlePlaylistSearch(debouncedPlaylistValue)
    }
  }, [debouncedPlaylistValue])

  // PUBLISH FLOW
  const handlePublishFilter = (value: any) => {
    if (value === 'null') {
      setPublishFilter(null)
    } else if (value === 'true') {
      setPublishFilter(true)
    } else {
      setPublishFilter(false)
    }
  }

  useEffect(() => {
    if (sectionStore.handlePublishSearch) {
      sectionStore?.handlePublishSearch(publishFilter)
    }
  }, [publishFilter])

  // TYPE FLOW
  const handleTypeFilter = (value: string) => {
    const formatValue = value === 'null' ? '' : value?.trim()

    setTypeFilter(formatValue)
  }

  useEffect(() => {
    if (sectionStore.handleTypeSearch) {
      sectionStore?.handleTypeSearch(typeFilter)
    }
  }, [typeFilter])

  // STATUS FLOW
  const handleStatusFilter = (value: string) => {
    const formatValue = value === 'null' ? '' : value?.trim()

    setStatusFilter(formatValue)
  }

  useEffect(() => {
    if (sectionStore.handleStatusSearch) {
      sectionStore?.handleStatusSearch(statusFilter)
    }
  }, [statusFilter])

  // SOURCE FLOW
  const handleSourceFilter = (value: string) => {
    const formatValue = value === 'null' ? '' : value?.trim()

    if (storeName === StoreNames.STYNGLISTS_STORE && formatValue === 'ROYALTY_FREE') {
      setSelectedGenres([])
    }

    setSourceFilter(formatValue)
  }

  useEffect(() => {
    if (sectionStore.handleSourceSearch) {
      sectionStore?.handleSourceSearch(sourceFilter)
    }
  }, [sourceFilter])

  return (
    <div className={styles.filtersWrapper}>
      {filters.includes(FilterFields.ID) && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Id'}</h5>
          <TextField
            data-test="filter-id"
            autoComplete="off"
            placeholder={Text.fields.inputPlaceholder}
            name="id"
            inputProps={{ maxLength: 36 }}
            value={idFilter}
            className={styles.customInput}
            onChange={(e) => handleIdFilter(e.target.value)}
            onPaste={(e) => {
              e.preventDefault()

              handleIdFilter(e.clipboardData.getData('text').trim())
            }}
          />
        </div>
      )}
      {filters.includes(FilterFields.ISRC) && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'ISRC'}</h5>
          <TextField
            data-test="filter-isrc"
            autoComplete="off"
            placeholder={Text.fields.inputPlaceholder}
            name="isrc"
            value={isrcFilter}
            className={styles.customInput}
            onChange={(e) => handleIsrcFilter(e.target.value)}
            onPaste={(e) => {
              e.preventDefault()

              handleIsrcFilter(e.clipboardData.getData('text').trim())
            }}
          />
        </div>
      )}
      {filters.includes(FilterFields.NAME) && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Name'}</h5>
          <TextField
            data-test="filter-name"
            autoComplete="off"
            placeholder={Text.fields.inputPlaceholder}
            name="name"
            value={nameFilter}
            className={styles.customInput}
            onChange={(e) => {
              handleNameFilter(e.target.value)
            }}
            onPaste={(e) => {
              e.preventDefault()

              handleNameFilter(e.clipboardData.getData('text').trim())
            }}
          />
        </div>
      )}
      {filters.includes(FilterFields.AGE_RESTRICTION) && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Age restriction'}</h5>
          <TextField
            data-test="filter-age-restriction"
            autoComplete="off"
            placeholder={Text.fields.inputPlaceholder}
            name="ageRestriction"
            value={ageRestrictionFilter}
            className={styles.customInput}
            onChange={(e) => handleAgeRestrictionFilter(e.target.value)}
            onPaste={(e) => {
              e.preventDefault()

              handleAgeRestrictionFilter(e.clipboardData.getData('text').trim())
            }}
          />
        </div>
      )}
      {filters.includes(FilterFields.AVAILABILITY) && territoriesStatus === 'success' && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Availability'}</h5>
          <FormControl fullWidth className={styles.customAutocomplete}>
            <div className={styles.autocompleteWrapper}>
              <Autocomplete
                data-test="filter-availability"
                multiple
                disableCloseOnSelect
                id="availableCountries"
                className={styles.autocompleteField}
                isOptionEqualToValue={(option, value) => option.territoryCode === value.territoryCode}
                value={selectedTerritories}
                options={allTerritories}
                getOptionLabel={(option: Territory) => option.territoryName}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.territoryName}
                  </li>
                )}
                renderTags={() => null}
                renderInput={(params) => <MuiTextField {...params} placeholder="Search" />}
                onChange={handleChangeTerritories}
              />
              {selectedTerritories.length > 0 && (
                <Box className={styles.autocompleteFieldValues}>
                  {selectedTerritories.map((option) => (
                    <Chip
                      className={styles.autocompleteFieldValue}
                      key={option.territoryCode}
                      label={option.territoryName}
                      onDelete={() => handleDeleteTerritory(option.territoryCode)}
                    />
                  ))}
                </Box>
              )}
            </div>
          </FormControl>
        </div>
      )}

      {filters.includes(FilterFields.PLATFORM) && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Platform'}</h5>
          <TextField
            data-test="filter-platform"
            autoComplete="off"
            placeholder={Text.fields.inputPlaceholder}
            name="platform"
            value={platformFilter}
            className={styles.customInput}
            onChange={(e) => handlePlatformFilter(e.target.value)}
            onPaste={(e) => {
              e.preventDefault()

              handlePlatformFilter(e.clipboardData.getData('text').trim())
            }}
          />
        </div>
      )}
      {filters.includes(FilterFields.APPLICATION) && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Application'}</h5>
          <TextField
            data-test="filter-application"
            autoComplete="off"
            placeholder={Text.fields.inputPlaceholder}
            name="application"
            value={appFilter}
            className={styles.customInput}
            onChange={(e) => handleAppFilter(e.target.value)}
            onPaste={(e) => {
              e.preventDefault()

              handleAppFilter(e.clipboardData.getData('text').trim())
            }}
          />
        </div>
      )}
      {filters.includes(FilterFields.PREFIX) && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Prefix'}</h5>
          <TextField
            data-test="filter-prefix"
            autoComplete="off"
            placeholder={Text.fields.inputPlaceholder}
            name="prefix"
            value={prefixFilter}
            className={styles.customInput}
            onChange={(e) => handlePrefixFilter(e.target.value)}
            onPaste={(e) => {
              e.preventDefault()

              handlePrefixFilter(e.clipboardData.getData('text').trim())
            }}
          />
        </div>
      )}
      {filters.includes(FilterFields.ARTIST) && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Artist'}</h5>
          <TextField
            data-test="filter-artist"
            autoComplete="off"
            placeholder={Text.fields.inputPlaceholder}
            name="artist"
            value={artistFilter}
            className={styles.customInput}
            onChange={(e) => handleArtistFilter(e.target.value)}
            onPaste={(e) => {
              e.preventDefault()

              handleArtistFilter(e.clipboardData.getData('text').trim())
            }}
          />
        </div>
      )}
      {filters.includes(FilterFields.SONG_NAME) && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Song name'}</h5>
          <TextField
            data-test="filter-song-name"
            autoComplete="off"
            placeholder={Text.fields.inputPlaceholder}
            name="songName"
            value={songNameFilter}
            className={styles.customInput}
            onChange={(e) => handleSongNameFilter(e.target.value)}
            onPaste={(e) => {
              e.preventDefault()

              handleSongNameFilter(e.clipboardData.getData('text').trim())
            }}
          />
        </div>
      )}
      {filters.includes(FilterFields.GENRE) && genresStatus === 'success' && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Genre'}</h5>
          <FormControl fullWidth className={styles.customAutocomplete}>
            <div className={styles.autocompleteWrapper}>
              <Autocomplete
                data-test="filter-genre"
                multiple
                disableCloseOnSelect
                id="genre"
                className={styles.autocompleteField}
                disabled={storeName === StoreNames.STYNGLISTS_STORE && sourceFilter === 'ROYALTY_FREE'}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={selectedGenres}
                options={allGenres}
                getOptionLabel={(option: Genre) => option.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                  </li>
                )}
                renderTags={() => null}
                renderInput={(params) => <MuiTextField {...params} placeholder="Search" />}
                onChange={handleChangeGenres}
              />
              {selectedGenres.length > 0 && (
                <Box className={styles.autocompleteFieldValues}>
                  {selectedGenres.map((option) => (
                    <Chip
                      className={styles.autocompleteFieldValue}
                      key={option.id}
                      label={option.name}
                      onDelete={() => handleDeleteGenre(option.id)}
                    />
                  ))}
                </Box>
              )}
            </div>
          </FormControl>
        </div>
      )}
      {filters.includes(FilterFields.PLAYLIST) && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Playlist'}</h5>
          <TextField
            data-test="filter-playlist"
            autoComplete="off"
            placeholder={Text.fields.inputPlaceholder}
            name="playlist"
            value={playlistFilter}
            className={styles.customInput}
            onChange={(e) => handlePlaylistFilter(e.target.value)}
            onPaste={(e) => {
              e.preventDefault()

              handlePlaylistFilter(e.clipboardData.getData('text').trim())
            }}
          />
        </div>
      )}
      {filters.includes(FilterFields.PUBLISHED) && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Published'}</h5>
          <Select
            data-test="filter-published"
            fullWidth
            value={publishFilter === null ? 'null' : publishFilter.toString()}
            options={publishFilterOptions}
            className={styles.customSelect}
            onChange={(e) => handlePublishFilter(e)}
          />
        </div>
      )}
      {filters.includes(FilterFields.TYPE) && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Type'}</h5>
          <Select
            fullWidth
            displayEmpty
            data-test="filter-type"
            value={typeFilter === '' ? 'null' : typeFilter.toString()}
            options={typeFilterOptions}
            className={styles.customSelect}
            onChange={(e) => handleTypeFilter(e)}
          />
        </div>
      )}
      {filters.includes(FilterFields.STATUS) && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Status'}</h5>
          <Select
            fullWidth
            displayEmpty
            data-test="filter-status"
            value={statusFilter === '' ? 'null' : statusFilter.toString()}
            options={statusFilterOptions}
            className={styles.customSelect}
            onChange={(e) => handleStatusFilter(e)}
          />
        </div>
      )}
      {filters.includes(FilterFields.SOURCE) && (
        <div className={styles.filter}>
          <h5 className={styles.label}>{'Source'}</h5>
          <Select
            fullWidth
            displayEmpty
            data-test="filter-source"
            value={sourceFilter === '' ? 'null' : sourceFilter.toString()}
            options={sourceFilterOptions}
            className={styles.customSelect}
            onChange={(e) => handleSourceFilter(e)}
          />
        </div>
      )}
    </div>
  )
}

export default SectionFilters
