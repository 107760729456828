/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import * as yup from 'yup'
import { useFormik } from 'formik'

import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Tab from '@mui/material/Tab'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'

import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import useStores from '../../../common/hook/useStore'

import FormStore from '../../../common/FormStore'
import API from '../../../common/api'
import { PlaylistTypes, EditPlaylistTabs } from '../../../common/constants'
import { Territory, TerritoryAvailability } from 'common/api/territory/territory'

import TextField from '../../../ui/TextField/TextField'
import { TextField as MuiTextField } from '@mui/material'
import Spinner from '../../../ui/Spinner/Spinner'
import Button, { ButtonVariant } from '../../../ui/Button/Button'

import Text from '../../../locale/strings'
import LicensedPlaylistTable from '../../components/LicensedPlaylistTable/LicensedPlaylistTable'
import RoyaltyFreePlaylistTable from '../../components/RoyaltyFreePlaylistTable/RoyaltyFreePlaylistTable'
import RadioAdGroup from 'radioAdGroups/pages/RadioAdGroup/RadioAdGroup'
import { PlaylistsRoute } from '../../index'

import styles from './EditPlaylist.module.scss'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const schema = yup.object({
  name: yup
    .string()
    .matches(/^\S.*\S$|^\S$/, 'No leading or trailing spaces are allowed')
    .required('Name is required')
    .max(70, 'Name must be at most 70 characters'),
  appIds: yup.string().required('Application is required'),
})

interface EditPlaylistFormValues {
  name: string
  platformId: string
  appIds: string
  availability: object
}

interface App {
  appId: string
  applicationName: string
  isBound: boolean
}

const initialValues: EditPlaylistFormValues = {
  name: '',
  platformId: '',
  appIds: '',
  availability: {},
}

interface PlaylistProps {
  formStore: FormStore
  appId: string | undefined
  playlistId: string
  playlistBasicInfoData: any
  playlistBasicInfoStatus: string
  playlistBasicInfoRefetch: any
}

const EditPlaylist = ({
  formStore,
  appId,
  playlistId,
  playlistBasicInfoData,
  playlistBasicInfoStatus,
  playlistBasicInfoRefetch,
}: PlaylistProps) => {
  const [tabValue, setTabValue] = useState(EditPlaylistTabs.GENERAL_INFORMATION_TAB)

  const [selectedApp, setSelectedApp] = useState<App | null>(null)
  const [availableApps, setAvailableApps] = useState<App[]>([])

  const [selectedCountriesArray, setSelectedCountriesArray] = useState<Territory[]>([])
  const [availableCountriesArray, setAvailableCountriesArray] = useState<Territory[]>([])

  const { navigationStore, notificationsStore } = useStores()

  useEffect(() => {
    if (typeof playlistBasicInfoData !== 'undefined') {
      const correctFormatForAppAutocomplete: App[] = []

      const correctFormatForAutocomplete: Territory[] = []
      const initialSelectedValues: Territory[] = []

      playlistBasicInfoData.applications.forEach(function (app: any) {
        correctFormatForAppAutocomplete.push(app)

        if (app.isBound) {
          setSelectedApp(app)
        }
      })

      setAvailableApps(correctFormatForAppAutocomplete)

      playlistBasicInfoData.availability.forEach(function (territory: TerritoryAvailability) {
        correctFormatForAutocomplete.push(territory)

        if (territory.isAvailable) {
          initialSelectedValues.push(territory)
        }
      })

      setAvailableCountriesArray(correctFormatForAutocomplete)
      setSelectedCountriesArray(initialSelectedValues)
    }
  }, [playlistBasicInfoData])

  const EditPlaylistMutation = useMutation<any, Error, any>(
    (body: any) => {
      return API.playlist.updateBasicInfo(body)
    },
    {
      onSuccess: () => {
        resetForm()
        navigationStore.goToPage(PlaylistsRoute.path)
        notificationsStore.successNotification('Playlist successfully edited')
      },
      onError: (error: any) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const handleSubmit = async (formValues: EditPlaylistFormValues) => {
    formStore.handleSubmit(() => {
      if (formValues.name === '') {
        return
      }

      EditPlaylistMutation.mutate({
        playlistId: playlistId,
        name: formValues.name.trim(),
        platformId: playlistBasicInfoData.platformId,
        appIds: formValues.appIds.split(' '),
        selectedCountries: selectedCountriesArray.map((item) => item.territoryCode),
      })
    })
  }

  const formik = useFormik<EditPlaylistFormValues>({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  })

  const { values, setFieldValue, resetForm } = formik

  useEffect(() => {
    resetForm({
      values: {
        name: playlistBasicInfoData.name,
        platformId: '',
        appIds: '',
        availability: {},
      },
    })
  }, [playlistBasicInfoData, resetForm])

  const handleTabChange = (_: React.SyntheticEvent, newValue: EditPlaylistTabs) => {
    setTabValue(newValue)

    if (newValue === EditPlaylistTabs.GENERAL_INFORMATION_TAB) {
      playlistBasicInfoRefetch()
    }
  }

  const handleAvailabilityChange = (e: React.SyntheticEvent, value: Territory[]) => {
    setSelectedCountriesArray(value)
  }

  const handleDeleteCountry = (value: any) => {
    setSelectedCountriesArray((countryArray) =>
      countryArray.filter((countryOption) => countryOption.territoryCode !== value),
    )
  }

  const handleAppChange = (e: React.SyntheticEvent, value: any) => {
    setSelectedApp(value)
  }

  useEffect(() => {
    setFieldValue('appIds', selectedApp?.appId)
  }, [selectedApp])

  const renderGeneralInfo = () => {
    return (
      <div className={styles.generalInfo}>
        <TextField
          fullWidth
          data-test="name-field"
          className={styles.textField}
          label={Text.page.playlists.create.fields.name}
          name="name"
          value={values.name}
          error={formik.errors.name}
          onChange={formik.handleChange}
        />
        <TextField
          fullWidth
          disabled
          className={styles.textField}
          label={'Platform *'}
          value={playlistBasicInfoData.platformName}
          error={formik.errors.platformId}
        />
        <div className={styles.autocompleteWrapper}>
          <Autocomplete
            data-test="countries-field"
            id="applications"
            className={styles.autocompleteField}
            value={selectedApp}
            options={availableApps}
            getOptionLabel={(option: any) => option.applicationName}
            renderTags={() => null}
            renderInput={(params) => (
              <MuiTextField {...params} label={'Application *'} placeholder="Search" error={selectedApp === null} />
            )}
            onChange={handleAppChange}
          />
        </div>
        {Object.keys(availableCountriesArray).length !== 0 ? (
          <div className={styles.autocompleteWrapper}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              data-test="available-countries-field"
              id="availableCountries"
              className={styles.autocompleteField}
              isOptionEqualToValue={(option, value) => option.territoryCode === value.territoryCode}
              value={selectedCountriesArray}
              options={availableCountriesArray}
              getOptionLabel={(option: Territory) => option.territoryCode}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option.territoryName}
                </li>
              )}
              renderTags={() => null}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={
                    playlistBasicInfoData.type === PlaylistTypes.LICENSED
                      ? Text.page.playlists.edit.subsetSectionTitle
                      : Text.page.playlists.edit.blockedSubsetSectionTitle
                  }
                  placeholder="Search"
                />
              )}
              onChange={handleAvailabilityChange}
            />
            <Box className={styles.autocompleteFieldValues}>
              {selectedCountriesArray.map((option) => (
                <Chip
                  className={styles.autocompleteFieldValue}
                  key={option.territoryCode}
                  label={option.territoryName}
                  onDelete={() => handleDeleteCountry(option.territoryCode)}
                />
              ))}
            </Box>
          </div>
        ) : (
          <p>{Text.page.playlists.edit.noSubset}</p>
        )}

        <div className={styles.submitContainer}>
          <Button
            data-test="cancel-button"
            variant={ButtonVariant.OUTLINED}
            onClick={() => navigationStore.goToPage(PlaylistsRoute.path)}
          >
            {Text.common.cancel}
          </Button>
          <Button data-test="submit-button" className={styles.saveButton} onClick={formik.handleSubmit}>
            {Text.common.save}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.playlistEdit}>
      <Toolbar className={styles.toolbar}>
        <h6 className={styles.pageTitle}>Edit playlist</h6>
      </Toolbar>
      <Box>
        {playlistBasicInfoStatus === 'loading' ? (
          <Spinner />
        ) : (
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList aria-label="Playlist page tabs" onChange={handleTabChange}>
                <Tab label="General" value={EditPlaylistTabs.GENERAL_INFORMATION_TAB} />
                <Tab label="Tracks" value={EditPlaylistTabs.PLAYLIST_TRACKS_TAB} />
                {playlistBasicInfoData.monetizationType === 'INTERNAL_AD_FUNDED' && (
                  <Tab label="Ads Management" value={EditPlaylistTabs.ADS_MANAGEMENT} />
                )}
              </TabList>
            </Box>
            <TabPanel value={EditPlaylistTabs.GENERAL_INFORMATION_TAB}>{renderGeneralInfo()}</TabPanel>
            <TabPanel value={EditPlaylistTabs.PLAYLIST_TRACKS_TAB}>
              {playlistBasicInfoData.type === PlaylistTypes.LICENSED ? (
                <LicensedPlaylistTable appId={appId} playlistId={playlistId} />
              ) : (
                <RoyaltyFreePlaylistTable appId={appId} playlistId={playlistId} />
              )}
            </TabPanel>
            <TabPanel value={EditPlaylistTabs.ADS_MANAGEMENT}>
              <RadioAdGroup playlistBasicInfoData={playlistBasicInfoData} />
            </TabPanel>
          </TabContext>
        )}
      </Box>
    </div>
  )
}

export default EditPlaylist
