import { useQuery } from 'react-query'
import API from '../common/api'
import * as R from 'ramda'
import { action, observable } from 'mobx'

import { Platform, PlatformResponse } from '../common/api/platforms/platforms'
import PaginationStore from '../common/PaginationStore'

class PlatformsStore extends PaginationStore {
  @observable
  platformId: string = ''

  @observable
  id: string = ''

  @observable
  name: string = ''

  @observable
  prefix: string = ''

  @action
  handleIdSearch = (filter: string) => {
    this.id = filter?.trim()
    this.changePage()
  }

  @action
  handleNameSearch = (filter: string) => {
    this.name = filter?.trim()
    this.changePage()
  }

  @action
  handlePrefixSearch = (filter: string) => {
    this.prefix = filter?.trim()
    this.changePage()
  }
}

export const usePlatformListQuery = (filterStore: PlatformsStore) => {
  const { pageSize, page, id, name, prefix } = filterStore

  return useQuery<PlatformResponse>(
    ['platforms-list', pageSize, page, id, name, prefix],
    () => {
      return API.platforms.list({
        page: page,
        size: pageSize,
        id,
        name,
        offerCodePrefix: prefix,
      })
    },
    {
      keepPreviousData: false,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  )
}

export const usePlatformAppsListQuery = (platformId: string) => {
  return useQuery<any>(
    [`platform-apps-list-${platformId}`],
    () => {
      return API.platforms.getPlatformAppslist({
        platformId: platformId,
      })
    },
    {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  )
}

export const usePlatformQuery = (id: string) => {
  return useQuery<Platform>(
    ['platform', id],
    () => {
      return API.platforms.getPlatform({
        platformId: id,
      })
    },
    {
      enabled: !R.isEmpty(id),
      keepPreviousData: true,
      retry: 1,
    },
  )
}

export default PlatformsStore
