import { action, observable } from 'mobx'
import { useQuery, useInfiniteQuery } from 'react-query'
import * as R from 'ramda'

import { Company } from 'common/api/company/company'
import API from 'common/api/index'
import PaginationStore from 'common/PaginationStore'

import { StoreNames } from 'common/constants'

import { CompaniesResponse, EditCompanyEntity } from '../common/api/company/company'

class CompaniesStore extends PaginationStore {
  storeName: string = StoreNames.COMPANIES_STORE

  @observable
  name: string = ''

  @observable
  id: string = ''

  @observable
  platform: string = ''

  @observable
  companies: Company[] = []

  @observable
  currentCompany: Nullable<Company> = null

  @action
  handleNameSearch = (filter: string) => {
    this.name = filter?.trim()
    this.changePage()
  }

  @action
  handlePlatformSearch = (filter: string) => {
    this.platform = filter?.trim()
    this.changePage()
  }

  @action
  handleIdSearch = (filter: string) => {
    this.id = filter?.trim()
    this.changePage()
  }
}

export const useCompaniesQuery = () => {
  return useQuery<CompaniesResponse>(
    ['companies'],
    () => {
      return API.company.list()
    },
    {
      keepPreviousData: false,
      retry: 1,
    },
  )
}

export const useLoadMoreCompaniesQuery = () => {
  return useInfiniteQuery<CompaniesResponse>(
    ['load-more-companies'],
    async ({ pageParam = 1 }) => {
      const response = await API.company.all({
        page: pageParam,
        size: 30,
      })

      return response
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length === lastPage.pageCount) {
          return
        }

        return pages.length + 1
      },
    },
  )
}

export const useAllCompaniesQuery = (filterStore: CompaniesStore) => {
  const { pageSize, page, name, id, platform } = filterStore

  return useQuery<CompaniesResponse>(
    ['all-companies', pageSize, page, name, id, platform],
    () => {
      return API.company.all({
        page: page,
        pageSize: pageSize,
        name,
        id,
        platformName: platform,
      })
    },
    {
      keepPreviousData: false,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  )
}

export const useCompanyQuery = (companyId: string) => {
  return useQuery<EditCompanyEntity>(
    ['company', companyId],
    () => {
      return API.company.get({
        companyId: companyId,
      })
    },
    {
      enabled: !R.isEmpty(companyId),
      keepPreviousData: true,
      retry: 1,
    },
  )
}

export default CompaniesStore
