import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'

import API from 'common/api'

import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import QueueMusicIcon from '@mui/icons-material/QueueMusic'

import useStores from '../../common/hook/useStore'
import { AppsRoute } from '../../apps/pages'
import { Duration } from 'luxon'
import { Playlist } from 'common/api/playlist/playlist'
import Text from '../../locale/strings'

import { truncateText } from 'common/utils'

import Button, { ButtonVariant } from '../../ui/Button/Button'
import DndTable from 'ui/DndTable/DndTable'

import styles from './appInfo.module.scss'

interface AppInfoProps {
  app: any
  playlists: Playlist[]
  refetch: () => void
}

const AppInfo = ({ app, playlists, refetch }: AppInfoProps) => {
  const { navigationStore, notificationsStore } = useStores()
  const [value, setValue] = useState('1')
  const [dataTable, setDataTable] = useState<any>([])

  const mutationPlaylistOrder = useMutation<any, Error, any>(
    (body: any) => {
      return API.app.updatePlaylistPriority(body)
    },
    {
      onSuccess: () => {
        notificationsStore.successNotification('Order modified successfully')
        refetch()
      },
      onError: (error) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const columns = [
    {
      name: 'Id',
      width: '20%',
    },
    {
      name: 'Name',
      width: '35%',
    },
    {
      name: 'Duration',
      width: '10%',
    },
    {
      name: 'Number of Tracks',
      width: '15%',
    },
    {
      name: 'Published',
      width: '20%',
    },
    {
      name: 'PriorityStatus',
      width: '0%',
    },
    {
      name: 'Priority',
      width: '0%',
    },
  ]

  useEffect(() => {
    playlists.sort((a, b) => {
      if (a.priority === null) {
        return 1
      }

      if (b.priority === null) {
        return -1
      }

      return a.priority - b.priority
    })

    setDataTable(
      playlists.map((playlist: Playlist) => [
        playlist.id,
        truncateText(playlist.name),
        Duration.fromISO(playlist.duration).toFormat('hh:mm:ss'),
        playlist.numberOfTracks,
        playlist.publishedAt === null ? 'not published' : 'published',
        playlist.priority === null ? 'unordered' : 'ordered',
        playlist.priority,
      ]),
    )
  }, [playlists])

  const handleUpdateOrder = (newObject: any) => {
    setDataTable([...newObject])
  }

  const saveOrder = () => {
    const ordered = dataTable.filter((currentArray: any) => currentArray[currentArray.length - 2] !== 'unordered')
    const unordered = dataTable.filter((currentArray: any) => currentArray[currentArray.length - 2] !== 'ordered')

    const orderedWithIndex = ordered.map((subArray: any, index: number) => {
      if (subArray[subArray.length - 2] === 'ordered') {
        subArray[subArray.length - 1] = index + 1
      }

      return subArray
    }, {})

    const mergedArrays = [...orderedWithIndex, ...unordered]

    mutationPlaylistOrder.mutate({
      appId: app.id,
      playlistsPriorityMap: mergedArrays.reduce((acc: any, curr: any) => {
        const secondToLast = curr[curr.length - 2]

        if (secondToLast === 'unordered') {
          acc[curr[0]] = null
        } else {
          acc[curr[0]] = curr[curr.length - 1]
        }

        return acc
      }, {}),
    })
  }

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Toolbar sx={{ backgroundColor: '#1976d2' }}>
        <Typography sx={{ ml: 2, flex: 1, color: '#fff' }} variant="h6" component="div">
          {app.name}
        </Typography>
      </Toolbar>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList aria-label="app-tabs" onChange={handleChange}>
            <Tab
              value="1"
              icon={<QueueMusicIcon fontSize="small" />}
              iconPosition="start"
              label={Text.page.app.radioPlaylists}
              sx={{ minHeight: '48px', height: '48px', textTransform: 'none' }}
            />
          </TabList>
        </Box>
        <TabPanel className={styles.container} value="1">
          <DndTable columns={columns} data={dataTable} updateOrder={handleUpdateOrder} />
        </TabPanel>
      </TabContext>
      <div className={styles.submitContainer}>
        <Button variant={ButtonVariant.OUTLINED} onClick={() => navigationStore.goToPage(AppsRoute.path)}>
          {Text.common.cancel}
        </Button>
        <Button onClick={() => saveOrder()}>{Text.common.save}</Button>
      </div>
    </Box>
  )
}

export default AppInfo
