import React from 'react'

import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '../../TextField/TextField'

import Text from '../../../locale/strings'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import styles from './JsonDataModalRows.module.scss'

interface JsonDataModalRowsProps {
  metadataArray: any
  handleInputUpdate: (field: string, event: React.ChangeEvent<HTMLInputElement>, index: number) => void
  onDeleteClick: (index: number) => void
  onAddClick: () => void
}

const JsonDataModalRows = ({ metadataArray, handleInputUpdate, onDeleteClick, onAddClick }: JsonDataModalRowsProps) => {
  return (
    <React.Fragment>
      <div className={styles.rowWrapper}>
        {metadataArray.map((item: { [key: string]: string | number }, index: number) => (
          <div className={styles.rowContent} key={index}>
            <TextField
              className={styles.textField}
              label={'Key'}
              name="key"
              value={item.key}
              onChange={(e) => handleInputUpdate('key', e, index)}
            />
            <TextField
              className={styles.textField}
              label={'Value'}
              name="value"
              value={item.value}
              onChange={(e) => handleInputUpdate('value', e, index)}
            />
            <DeleteIcon color={'action'} className={styles.deleteIcon} onClick={() => onDeleteClick(index)} />
          </div>
        ))}
      </div>
      <div className={styles.addNewwWrapper}>
        <div className={styles.addButtonRow}>
          <LoadingButton
            size="small"
            color="primary"
            variant="outlined"
            endIcon={<AddIcon />}
            onClick={() => onAddClick()}
          >
            <span>{Text.common.add}</span>
          </LoadingButton>
        </div>
      </div>
    </React.Fragment>
  )
}

export default JsonDataModalRows
