/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react'
import DraggableItem from './../DraggableItem/DraggableItem'
import DropArea from './../DropArea/DropArea'

import styles from './droppableContainer.module.scss'

interface DroppableContainerProps {
  columns: any[]
  items: any[]
  name: string
  onDrop: (name: string, position: number) => void
  setActiveItem: (activeCardIndex: number | null) => void
}

const DroppableContainer = ({ columns, items, name, onDrop, setActiveItem }: DroppableContainerProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [containerEmpty, setContainerEmpty] = useState<boolean>(false)

  useEffect(() => {
    let isEmpty = false

    isEmpty = items.some((subArray) => subArray[subArray.length - 2] === name)

    setContainerEmpty(!isEmpty)
  }, [items])

  return (
    <div ref={containerRef} className={styles.droppableContainerWrapper}>
      <h4 className={styles.name}>{name.toUpperCase()}</h4>
      <div className={styles.columnsWrapper}>
        {columns.map(
          (column: any, index: number) =>
            column.name !== 'PriorityStatus' &&
            column.name !== 'Priority' && (
              <span
                className={styles.column}
                style={{
                  width: column.width,
                }}
                key={index}
              >
                {column.name}
              </span>
            ),
        )}
      </div>
      <section className={containerEmpty ? styles.showDropAreaPlaceholder : styles.hideDropAreaPlaceholder}>
        Drop Item
      </section>
      <DropArea name={name} index={0} onDrop={onDrop} />
      {items.map(
        (item, index) =>
          name === item[item.length - 2] && (
            <React.Fragment key={index}>
              <DraggableItem columns={columns} item={item} setActiveItem={setActiveItem} index={index} />
              <DropArea name={name} index={index + 1} onDrop={onDrop} />
            </React.Fragment>
          ),
      )}
    </div>
  )
}

export default DroppableContainer
