/* eslint-disable jsx-control-statements/jsx-for-require-each */
import React, { useEffect, useState } from 'react'
import { Duration } from 'luxon'

import { useStyngDeactivate } from 'common/hook/useStyngDeactivate'
import { useStyngActivate } from 'common/hook/useStyngActivate'

import { useRoyaltyFreeStyngsQuery } from '../../StyngsStore'

import Text from 'locale/strings'

import Button from 'ui/Button/Button'
import Spinner from 'ui/Spinner/Spinner'
import Table from 'ui/Table/Table'
import Pagination from 'ui/Pagination/Pagination'
import JsonDataModalWithPlatform from 'ui/JsonDataModal/JsonDataModalWithPlatform/JsonDataModalWithPlatform'

import { CreateRFStyngRoute, EditRFStyngRoute } from '../../index'
import { RoyaltyFreeStyng } from 'common/api/styng/styng'
import useStores from 'common/hook/useStore'
import { truncateText } from 'common/utils'
import { INITIAL_PAGE, FileType, ApiStatuses } from 'common/constants'

import styles from './StyngsRoyaltyFreeTable.module.scss'

const StyngsRoyaltyFreeTable = () => {
  const { styngsStore, navigationStore } = useStores()
  const { data: dataRf, refetch: refetchRf, status: statusRf } = useRoyaltyFreeStyngsQuery(styngsStore)

  const styngsRf = dataRf?.items ?? []
  const pagesCountRf = dataRf?.pagesCount ?? 1

  const [selectedStyng, setSelectedStyng] = useState<RoyaltyFreeStyng | null>(null)
  const [jsonDataModal, setJsonDataModal] = useState<boolean>(false)

  useEffect(() => {
    styngsStore.changePage(INITIAL_PAGE)
  }, [])

  // Pagination logic
  const handleChangePage = (value: number) => {
    styngsStore.changePage(value)
  }

  // Table data
  const columns = [
    'Image',
    'Id',
    'Track name',
    'Artists',
    'Styng name',
    'Duration',
    'Published',
    'External Metadata',
    'Actions',
  ]

  const dataTable = styngsRf.map((styng: RoyaltyFreeStyng) => [
    styng.imageUrl,
    styng.id,
    styng.trackName,
    styng.artists,
    truncateText(styng.name),
    Duration.fromISO(styng.duration).toFormat('mm:ss'),
    styng.isActive.toString(),
    Object.keys(styng.metadata).length === 0 ? 'NULL' : '',
  ])

  const onActivateRFMutation = useStyngActivate((_: any) => {
    refetchRf()
  })

  const onDeactivateRFMutation = useStyngDeactivate((_: any) => {
    refetchRf()
  })

  const handleActivate = (id: string, active: string) => {
    if (active === 'false') {
      onActivateRFMutation.mutate({ styngId: id })
    } else {
      onDeactivateRFMutation.mutate({ styngId: id })
    }
  }

  const handleOpenAdJsonModal = (id: string) => {
    const styng = styngsRf.filter((styng: RoyaltyFreeStyng) => styng.id === id)

    setSelectedStyng(styng[0])
    setJsonDataModal(true)
  }

  const handleCloseAdJsonModal = () => {
    setSelectedStyng(null)
    setJsonDataModal(false)
  }

  const handleCreateRFStyng = () => {
    navigationStore.goToPage(CreateRFStyngRoute.path)
  }

  const handleEditClick = (styngId: string) => {
    navigationStore.goToPage(EditRFStyngRoute.getUrl({ id: styngId }))
  }

  return (
    <div className={styles.container}>
      {onActivateRFMutation.isLoading || onDeactivateRFMutation.isLoading || statusRf === ApiStatuses.LOADING ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <div className={styles.header}>
            <Button data-test="create-button" onClick={() => handleCreateRFStyng()}>
              {Text.navigation.createRFStyng}
            </Button>
          </div>

          <Table
            jsonData
            hideDelete
            columns={columns}
            data={dataTable}
            hiddenColumns={['Id', 'External Metadata']}
            publishedToggle={true}
            handleActivate={handleActivate}
            onJsonDataClick={handleOpenAdJsonModal}
            onEditClick={handleEditClick}
          />

          <If condition={pagesCountRf > 1}>
            <div className={styles.paginationContainer}>
              <div className={styles.pagination}>
                <Pagination page={styngsStore.page} count={pagesCountRf} onChange={handleChangePage} />
              </div>
            </div>
          </If>
        </React.Fragment>
      )}

      {jsonDataModal && (
        <JsonDataModalWithPlatform
          dataTypeId={selectedStyng?.id}
          data={selectedStyng?.metadata}
          dataType={FileType.STYNG}
          open={jsonDataModal}
          refetch={refetchRf}
          handleClose={handleCloseAdJsonModal}
        />
      )}
    </div>
  )
}

export default StyngsRoyaltyFreeTable
