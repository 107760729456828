import { useState } from 'react'

import DragHandleIcon from '@mui/icons-material/DragHandle'

import styles from './draggableItem.module.scss'

interface DraggableItemProps {
  item: any
  columns: any[]
  index: number
  setActiveItem: (activeCardIndex: number | null) => void
}

const DraggableItem = ({ item, columns, index, setActiveItem }: DraggableItemProps) => {
  const [draggableElement, setDraggableElement] = useState(false)

  return (
    <div
      draggable={draggableElement}
      className={styles.draggableItemWrapper}
      onDragStart={() => setActiveItem(index)}
      onDragEnd={() => setActiveItem(null)}
    >
      <DragHandleIcon
        className={styles.dragIcon}
        onMouseUp={(_) => setDraggableElement(false)}
        onMouseDown={(_) => setDraggableElement(true)}
      />
      {item.map(
        (currentItem: any, index: number) =>
          columns[index].name !== 'PriorityStatus' &&
          columns[index].name !== 'Priority' && (
            <span
              key={index}
              className={styles.draggableItem}
              style={{
                width: columns[index].width,
              }}
            >
              {currentItem}
            </span>
          ),
      )}
    </div>
  )
}

export default DraggableItem
