/* eslint-disable jsx-control-statements/jsx-for-require-each */
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useMutation } from 'react-query'

import useStores from 'common/hook/useStore'

import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import DateAdapter from '@mui/lab/AdapterLuxon'
import { DateTime } from 'luxon'
import {
  TextField as MuiTextField,
  Select as MuiSelect,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material'

import SectionFilters from 'ui/SectionFilters/SectionFilters'
import Table from 'ui/Table/Table'
import Pagination from 'ui/Pagination/Pagination'
import Button from 'ui/Button/Button'
import LoadingComponent from 'ui/Loading/LoadingPage'

import Text from 'locale/strings'
import ReportingStore, { useReportsQuery, useReportTypesQuery } from '../../ReportingStore'
import { Report, ReportType, ReportsRequest, ResubmitReportRequest } from 'common/api/reporting/reports'

import API from 'common/api'
import { ApiStatuses, TimePoints, FilterFields } from 'common/constants'

import styles from './ReportsTable.module.scss'

const YESTERDAY = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).minus({ days: 1 })
const INITIAL_PAGE = 0

interface ReportsProps {
  reportingStore: ReportingStore
}

const ReportsTable = ({ reportingStore }: ReportsProps) => {
  const { notificationsStore } = useStores()
  const { data: reports, status: reportsStatus, refetch: refetchReports } = useReportsQuery(reportingStore)
  const reportsList = reports?.reportGenerationRequests ?? []

  const { data: reportTypes } = useReportTypesQuery(true)
  const [reportType, setReportType] = useState<string>('')
  const [reportTypeOptions, setReportTypeOptions] = useState<ReportType[]>([])
  const [isReportTypeSelected, setIsReportTypeSelected] = useState<boolean>(false)

  const [dateFrom, setDateFrom] = useState<any>()
  const [dateTo, setDateTo] = useState<any>()
  const [minDateTo, setMinDateTo] = useState<any>()

  const [openStartCalendar, setOpenStartCalendar] = useState<boolean>(false)
  const [openEndCalendar, setOpenEndCalendar] = useState<boolean>(false)

  const [numberOfPages, setNumberOfPages] = useState<number>(INITIAL_PAGE)

  const mutationReportGenerateEvent = useMutation<any, Error, ReportsRequest>(
    (body: ReportsRequest) => {
      return API.reports.generateReport(body)
    },
    {
      onSuccess: () => {
        refetchReports()
        handleCurrentDate()
        setReportType('')

        notificationsStore.successNotification(
          'Report generation in progress. Please check your email inbox or spam folder when complete',
        )
      },
      onError: (error: any) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const mutationReportGenerateResubmitEvent = useMutation<any, Error, ResubmitReportRequest>(
    (body: ResubmitReportRequest) => {
      return API.reports.generateReportResubmit(body)
    },
    {
      onSuccess: () => {
        refetchReports()
        handleCurrentDate()
        setReportType('')

        notificationsStore.successNotification(
          'Report generation in progress. Please check your email inbox or spam folder when complete',
        )
      },
      onError: (error: any) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const handleOpenCalendar = (event: any, time: string) => {
    event.preventDefault()

    if (TimePoints.START === time) {
      setOpenStartCalendar(true)
      setOpenEndCalendar(false)
    } else {
      setOpenEndCalendar(true)
      setOpenStartCalendar(false)
    }
  }

  const columns = [
    'Id',
    'Is Retryable',
    'Report Type',
    'Start Date',
    'End Date',
    'Generation Date',
    'Status',
    'Actions',
  ]

  const dataTable = reportsList.map((report: Report) => [
    report.id,
    report.isRetryable ? 'true' : 'false',
    report.type,
    report.startDate,
    report.endDate,
    report.generationDate ? report.generationDate : '/',
    report.status,
  ])

  const handleCurrentDate = () => {
    setDateFrom(YESTERDAY.toFormat('yyyy-MM-dd'))

    setDateTo(YESTERDAY.toFormat('yyyy-MM-dd'))
  }

  const handleIsReportTypeSelected = () => {
    if (reportType.length === 0) {
      setIsReportTypeSelected(true)

      return true
    }

    setIsReportTypeSelected(false)

    return false
  }

  useEffect(() => {
    if (reportTypes) {
      setReportTypeOptions(reportTypes.reportTypes)
    }
  }, [reportTypes])

  const handleKeyDown = (event: any) => {
    event.preventDefault()
  }

  const renderInputDefault = (timeOption: string) => {
    return (props: any) => (
      <MuiTextField
        sx={{
          '& .MuiInputBase-root': {
            pointerEvents: 'none',
          },
        }}
        onClick={(e) => handleOpenCalendar(e, timeOption)}
        onKeyDown={handleKeyDown}
        {...props}
      />
    )
  }

  useEffect(() => {
    handleCurrentDate()
  }, [])

  const handleChangeReportType = (event: SelectChangeEvent) => {
    setReportType(event.target.value)
  }

  const handleDateFrom = (value: any) => {
    setDateFrom(DateTime.fromMillis(value.ts).toFormat('yyyy-MM-dd'))
  }

  useEffect(() => {
    setMinDateTo(DateTime.fromISO(dateFrom))

    setDateTo(YESTERDAY.toFormat('yyyy-MM-dd'))
  }, [dateFrom])

  const handleDateTo = (value: any) => {
    setDateTo(DateTime.fromMillis(value.ts).toFormat('yyyy-MM-dd'))
  }

  const generateReport = async () => {
    const isSelected = await handleIsReportTypeSelected()

    if (isSelected) {
      return
    }

    mutationReportGenerateEvent.mutate({
      dateFrom,
      dateTo,
      reportType,
    })
  }

  const handleChangePage = (value: number) => {
    reportingStore.changePage(value)
  }

  useEffect(() => {
    if (reports?.pagesCount) {
      setNumberOfPages(reports.pagesCount)
    }
  }, [reports])

  const handleResubmitClick = (id: string) => {
    mutationReportGenerateResubmitEvent.mutate({ requestId: id })
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.pickerWrapper}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <FormControl className={styles.customDatePicker}>
              <DatePicker
                disableHighlightToday
                label="Start Date"
                value={dateFrom}
                renderInput={renderInputDefault(TimePoints.START)}
                maxDate={YESTERDAY}
                open={openStartCalendar}
                onOpen={() => setOpenStartCalendar(true)}
                onClose={() => setOpenStartCalendar(false)}
                onChange={(newValue) => handleDateFrom(newValue)}
              />
            </FormControl>
            <FormControl className={styles.customDatePicker}>
              <DatePicker
                disableHighlightToday
                label="End Date"
                value={dateTo}
                renderInput={renderInputDefault(TimePoints.END)}
                minDate={minDateTo ?? YESTERDAY}
                maxDate={YESTERDAY}
                open={openEndCalendar}
                onOpen={() => setOpenEndCalendar(true)}
                onClose={() => setOpenEndCalendar(false)}
                onChange={(newValue) => handleDateTo(newValue)}
              />
            </FormControl>
            <FormControl className={styles.customSelect}>
              <InputLabel>Report Type *</InputLabel>
              <MuiSelect
                label={'Report Type *'}
                name="availabilities"
                value={reportType}
                error={isReportTypeSelected}
                MenuProps={{
                  style: {
                    width: 1,
                    maxHeight: 300,
                    whiteSpace: 'normal',
                  },
                }}
                onChange={(e) => handleChangeReportType(e)}
              >
                {reportTypeOptions.map((reportType: ReportType) => (
                  <MenuItem style={{ whiteSpace: 'normal' }} key={reportType.type} value={reportType.type}>
                    {reportType.name}
                  </MenuItem>
                ))}
              </MuiSelect>
              <If condition={isReportTypeSelected}>
                <span className={styles.customError}>{Text.common.fieldIsRequired}</span>
              </If>
            </FormControl>
          </LocalizationProvider>

          <Button data-test="generate-button" className={styles.button} onClick={generateReport}>
            {Text.common.generate}
          </Button>
        </div>
        <SectionFilters sectionStore={reportingStore} filters={[FilterFields.TYPE, FilterFields.STATUS]} />
      </div>
      {reportsStatus === ApiStatuses.LOADING ? (
        <LoadingComponent />
      ) : (
        <Table
          hideEdit
          hideDelete
          hiddenColumns={['Id', 'Is Retryable']}
          columns={columns}
          data={dataTable}
          onResubmitClick={handleResubmitClick}
        />
      )}
      {numberOfPages > 0 && (
        <div>
          <div className={styles.paginationContainer}>
            <div className={styles.pagination}>
              <Pagination page={reportingStore.page} count={numberOfPages} onChange={handleChangePage} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default observer(ReportsTable)
