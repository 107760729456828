/* eslint-disable jsx-control-statements/jsx-for-require-each */
import React, { useState } from 'react'
import { Duration } from 'luxon'

import Collapse from '@mui/material/Collapse'
import Chip from '../../../ui/Chip/Chip'
import { Genre } from '../../../common/api/genre/genre'

import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { JsonValidationIcon } from './images'
import JsonDataModalWithPlatform from '../../../ui/JsonDataModal/JsonDataModalWithPlatform/JsonDataModalWithPlatform'

import { Styng } from '../../../common/api/styng/styng'
import { FileType } from '../../../common/constants'

import styles from './styngItem.module.scss'

interface StyngItemProps {
  styng: Styng
  onSelect: (styng: Styng) => void
  onDelete: (id: string) => void
  withoutGenres?: boolean
  appId?: string
  selectedId?: string
  refetch?: any
}

const StyngItem = ({ appId, styng, selectedId, withoutGenres, onSelect, onDelete, refetch }: StyngItemProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [jsonDataModal, setJsonDataModal] = useState<boolean>(false)

  const handleDelete = () => {
    onDelete(styng.id)
  }

  const handleOpenJsonModal = () => {
    setJsonDataModal(true)
  }

  const handleCloseJsonModal = () => {
    setJsonDataModal(false)
  }

  return (
    <div className={styles.container}>
      <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
      <div className={styles.nameHolder}>
        <h3>{styng.name}</h3>
      </div>
      <DeleteIcon className={styles.deleteIcon} color={'action'} onClick={handleDelete} />
      <div className={styles.svgIcon} onClick={() => handleOpenJsonModal()}>
        <JsonValidationIcon rule={!!styng.metadata} />
      </div>
      <Collapse unmountOnExit className={styles.collapse} timeout="auto" in={open}>
        <div className={styles.additionalInfo}>
          {!withoutGenres && (
            <h4>
              <For
                of={styng.genres}
                body={(genre: Genre) => <Chip key={genre.id} label={genre.name} className={styles.chips} />}
              />
            </h4>
          )}
          <h4>{Duration.fromISO(styng.duration).toFormat('mm:ss')}</h4>
        </div>
      </Collapse>

      {jsonDataModal && (
        <JsonDataModalWithPlatform
          dataTypeId={styng?.id}
          data={styng?.metadata}
          dataType={FileType.STYNG}
          open={jsonDataModal}
          refetch={refetch}
          handleClose={handleCloseJsonModal}
        />
      )}
    </div>
  )
}

export default React.memo(StyngItem)
