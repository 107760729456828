import { useState } from 'react'
import styles from './dropArea.module.scss'

interface DropAreaProps {
  name: string
  index: number
  onDrop: (name: string, position: number) => void
}

const DropArea = ({ name, index, onDrop }: DropAreaProps) => {
  const [showDrop, setShowDrop] = useState(false)

  return (
    <section
      className={showDrop ? styles.showDropAreaWrapper : styles.hideDropAreaWrapper}
      onDragEnterCapture={() => setShowDrop(true)}
      onDragLeave={() => setShowDrop(false)}
      onDrop={() => {
        onDrop(name, index)
        setShowDrop(false)
      }}
      onDragOver={(e) => e.preventDefault()}
    >
      Drop Item
    </section>
  )
}

export default DropArea
