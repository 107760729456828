/* eslint-disable @typescript-eslint/indent */
/* eslint-disable padding-line-between-statements */
import React, { Dispatch, useEffect, useState } from 'react'

import useStores from '../../common/hook/useStore'

import { Box, MenuItem, Select, TextField, Tooltip } from '@mui/material'

import Text from '../../locale/strings'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import LoadingButton from '@mui/lab/LoadingButton'
import { toProductTypeOptions } from '../../common/constants'
import { handleTypeNumber } from 'common/utils'

import { AppPrice } from '../../common/api/app/app'

import { useAppProductTypes } from '../ApplicationStore'

import styles from './prices.module.scss'

const availableStreamOptions = {
  count: 'STREAM',
  minutes: 'TIME',
}

interface ProcesProps {
  setNewPrices: Dispatch<React.SetStateAction<AppPrice[]>>
  appId?: string
  prices?: AppPrice[]
}

const Prices = ({ appId, prices, setNewPrices }: ProcesProps) => {
  const { notificationsStore } = useStores()
  const [currentPrices, setCurrentPrices] = useState<AppPrice[]>([])

  const { data: appProductTypes } = useAppProductTypes()
  const [productTypesOptions, setProductTypesOptions] = useState<any[]>([])

  useEffect(() => {
    if (typeof appProductTypes !== 'undefined') {
      setProductTypesOptions(appProductTypes.productTypes.map(toProductTypeOptions))
      setCurrentPrices(prices ?? [])
    }
  }, [appProductTypes])

  useEffect(() => {
    setNewPrices([...currentPrices])
  }, [currentPrices])

  const onAddClick = () => {
    setCurrentPrices([
      ...currentPrices,
      {
        productType: '',
        price: 4.99,
        availableStreamCount: '',
        availableStreamMinutes: '',
        appId: appId,
        currency: {
          appId: appId ?? '',
          id: 3,
          imageId: '7709f3d2-b704-4b5e-b3c3-0288441970b3',
          name: 'STAR',
        },
      },
    ])
  }

  const onDeleteClick = (key: number) => {
    setCurrentPrices((currentPrices) => currentPrices.filter((_, i) => i !== key))
  }

  const handleProductTypeChange = (selectedType: any, priceIndex: number) => {
    const selectedOptionValue = selectedType
    let isAlreadySelected = false

    currentPrices.forEach((el) => {
      if (selectedOptionValue === el.productType) {
        notificationsStore.infoNotification('Product type already added to the list')

        isAlreadySelected = true
      }
    })

    if (!isAlreadySelected) {
      setCurrentPrices((currentPrices: AppPrice[]) =>
        currentPrices.map((price: AppPrice, index: number) =>
          index === priceIndex ? { ...price, productType: selectedOptionValue } : price,
        ),
      )

      handleInputUpdate('', priceIndex, 'availableStreamCount')
      handleInputUpdate('', priceIndex, 'availableStreamMinutes')
    }
  }

  const handleInputUpdate = (selectedValue: string | number | null, priceIndex: number, inputType: string) => {
    setCurrentPrices((currentPrices: AppPrice[]) =>
      currentPrices.map((item: AppPrice, i: number) =>
        i === priceIndex ? { ...item, [inputType]: selectedValue } : item,
      ),
    )
  }

  const disableStreamInput = (selectedProductTypeName: string, streamOption: string) => {
    if (selectedProductTypeName === '') {
      return true
    }

    const getSelectedProductType = appProductTypes?.productTypes.filter(
      (productTypeOption: any) => productTypeOption.id === selectedProductTypeName,
    )[0]

    if (getSelectedProductType?.balanceUnit !== streamOption) {
      return true
    }

    return false
  }

  const renderPricesRows = () => {
    return currentPrices.map((item, index: number) => (
      <div className={styles.priceRow} key={index}>
        <Select
          data-test="product-type-field"
          className={styles.productTypeSelect}
          value={item.productType}
          onChange={(e) => handleProductTypeChange(e.target.value, index)}
        >
          {productTypesOptions.map((productType) => (
            <MenuItem key={productType.value} value={productType.value}>
              <Tooltip placement="top" title={productType.description} key={productType.value}>
                <label>{productType.label}</label>
              </Tooltip>
            </MenuItem>
          ))}
        </Select>
        <TextField
          data-test="price-field"
          type="number"
          className={styles.textField}
          label={Text.page.appEvents.create.fields.price}
          name={Text.page.appEvents.create.fields.price}
          value={item.price}
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
          onKeyPress={(e) => handleTypeNumber(e)}
          onChange={(e) => handleInputUpdate(e.target.value, index, 'price')}
        />
        <TextField
          data-test="available-stream-count-field"
          type="number"
          disabled={disableStreamInput(item.productType, availableStreamOptions.count)}
          className={styles.textField}
          label={Text.page.appEvents.create.fields.availableStreamCount}
          name={Text.page.appEvents.create.fields.availableStreamCount}
          value={item.availableStreamCount || ''}
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
          onKeyPress={(e) => handleTypeNumber(e)}
          onChange={(e) => handleInputUpdate(e.target.value, index, 'availableStreamCount')}
        />
        <TextField
          data-test="available-stream-minutes-field"
          type="number"
          disabled={disableStreamInput(item.productType, availableStreamOptions.minutes)}
          className={styles.textField}
          label={Text.page.appEvents.create.fields.availableStreamMinutes}
          name={Text.page.appEvents.create.fields.availableStreamMinutes}
          value={item.availableStreamMinutes || ''}
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
          onKeyPress={(e) => handleTypeNumber(e)}
          onChange={(e) => handleInputUpdate(e.target.value, index, 'availableStreamMinutes')}
        />
        <DeleteIcon color={'action'} className={styles.deleteIcon} onClick={() => onDeleteClick(index)} />
      </div>
    ))
  }

  return (
    <Box>
      <div className={styles.body}>
        {currentPrices && <div className={styles.rowWrapper}>{renderPricesRows()}</div>}
        <div className={styles.addNewwWrapper}>
          <div className={styles.addButtonRow}>
            <LoadingButton
              data-test="add-button"
              size="small"
              color="primary"
              variant="outlined"
              endIcon={<AddIcon />}
              onClick={() => onAddClick()}
            >
              <span>ADD</span>
            </LoadingButton>
          </div>
        </div>
      </div>
    </Box>
  )
}

export default Prices
