import { observer } from 'mobx-react'
import { thread } from 'lib/fn/thread'

import LoadingRemote from '../../common/LoadingRemote'
import { useParams } from 'react-router-dom'
import { useAppQuery, useAppPlaylistsQuery } from '../ApplicationStore'
import AppInfo from '../AppInfo/AppInfo'

interface ParamProps {
  appId?: string
}

const AppInfoPage = () => {
  const { appId = '' } = useParams<ParamProps>()
  const { data, refetch, status } = useAppPlaylistsQuery(appId)
  const playlists = data?.playlists ?? []
  const { data: app, status: appStatus } = useAppQuery(appId)

  return (
    <LoadingRemote status={status && appStatus}>
      <AppInfo app={app} playlists={playlists} refetch={refetch} />
    </LoadingRemote>
  )
}

export default thread(AppInfoPage, [observer])
